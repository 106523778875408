import React, { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import toast from 'react-hot-toast';
import AppContext from './AppContext';
import useIEReducer from './Reducer';

export default function Provider({ children }) {
  const [state, dispatch] = useIEReducer();
  const [secretAccessKey, setSecretAccessKey] = useState();
  const [secretAccessId, setSecretAccessId] = useState('');
  // const [secretAccessIdDemo, setSecretAccessIdDemo] = useState(''); // secretAccessId Demo
  const [inputBucketName, setInputBucketName] = useState('');
  // const [inputBucketNameDemo, setInputBucketNameDemo] = useState(''); // input bucket name demo
  const [clientDetails, setClientDetails] = useState({});
  const [productCode, setProductCode] = useState('');
  const [regulationCode, setRegulationCode] = useState('');
  const [refRowData, setRefRowData] = useState([]);
  const [refColumnData, setRefColumnData] = useState([]);
  // const [encryptIv, setEncryptIv] = useState(localStorage.getItem('RegAssureVal7'));
  // const secretKey = localStorage.getItem('userToken')?.slice(27, 47);
  const [encryptedClientValuesObj, setEncryptedClientValuesObj] = useState({});

  const handleDataDecryption = (encryptedString, secretKey) => {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedString, secretKey);
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      return decryptedData;
    } catch (error) {
      console.error(error.message);
      return null;
    }
  };

  const handleDataEncryption = (key, data, secretString) => {
    try {
      const encrptedString = CryptoJS.AES.encrypt(
        data,
        secretString.toString(),
      );
      localStorage.setItem(key, encrptedString);
      return encrptedString;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const handleEncryptValue = (data) => { // params of APIs
    // console.log(data, '---dataStoreProvider');
    const rawToken = localStorage.getItem('userToken');
    const encryptionKey = rawToken.slice(575, 607);
    if (encryptionKey !== null) {
      try {
        const keyBytes = CryptoJS.enc.Utf8.parse(encryptionKey);
        const iv = CryptoJS.lib.WordArray.random(16);
        const encrypted = CryptoJS.AES.encrypt(data, keyBytes, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        });
        const ivAndCiphertext = iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
        return ivAndCiphertext;
      } catch (error) {
        console.error('Encryption failed:', error);
        return null;
      }
    }
    return null;
  };

  const handleDecryptedValue = (encryptedString) => {
    const rawToken = localStorage.getItem('userToken');
    const encryptionKey = rawToken.slice(575, 607);
    try {
      const keyBytes = CryptoJS.enc.Utf8.parse(encryptionKey);
      const encryptedDataBytes = CryptoJS.enc.Base64.parse(encryptedString);
      const iv = CryptoJS.lib.WordArray.create(encryptedDataBytes.words.slice(0, 4), 16);
      const ciphertext = CryptoJS.lib.WordArray.create(encryptedDataBytes.words.slice(4),
        encryptedDataBytes.sigBytes - 16);
      const decrypted = CryptoJS.AES.decrypt(
        { ciphertext: ciphertext },
        keyBytes,
        {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        },
      );
      const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);
      return decryptedData;
    } catch (error) {
      return null;
    }
  };

  const valuesToBeEncrypt = ['clientId', 'role'];
  const handleEncryptValues = (clientData) => { // clientData is client object
    const updatedEncryptedValues = { ...encryptedClientValuesObj };
    valuesToBeEncrypt.forEach((value) => {
      const encryptedString = handleEncryptValue(clientData[value]);
      updatedEncryptedValues[value] = encryptedString;
    });
    setEncryptedClientValuesObj(updatedEncryptedValues);
  };

  const handleEncryptOtherParamsOfAPI = (paramsObj) => {
    const updatedEncryptedValues = {};
    Object.keys(paramsObj).forEach((param) => {
      updatedEncryptedValues[param] = handleEncryptValue(paramsObj[param]);
    });
    return updatedEncryptedValues;
  };

  const handleGetDecryptedData = () => {
    const rawToken = localStorage.getItem('userToken');
    if (rawToken === null) {
      console.log(null);
    } else {
      try {
        const secretKey = rawToken.slice(27, 47);
        const encryptedAccessId = localStorage.getItem('RegAssureVal1');
        const encryptedAccessKey = localStorage.getItem('RegAssureVal2');
        const encryptedBucketName = localStorage.getItem('RegAssureVal3');
        const encryptedClientDetails = localStorage.getItem('RegAssureVal4');
        const encryptedProductCode = localStorage.getItem('RegAssureVal5');
        const encryptedRegulationCode = localStorage.getItem('RegAssureVal6');
        // const encryptedBucketNameDemo = localStorage.getItem('RegAssureVal1111');
        // const encryptedAccessIdDemo = localStorage.getItem('RegAssureVal2222');
        // console.log('demo encrypted bucket name :', encryptedBucketNameDemo);
        // console.log('demo encrypted Access Id :', encryptedAccessIdDemo);
        setSecretAccessId(
          handleDataDecryption(encryptedAccessId, secretKey.toString()),
        );
        setSecretAccessKey(
          handleDataDecryption(encryptedAccessKey, secretKey.toString()),
        );
        setInputBucketName(
          handleDataDecryption(encryptedBucketName, secretKey.toString()),
        );
        // setInputBucketNameDemo(
        //   handleDataDecryption(encryptedBucketNameDemo, secretKey.toString()),
        // ); // input bucket name demo
        // setSecretAccessIdDemo(
        //   handleDataDecryption(encryptedAccessIdDemo, secretKey.toString()),
        // ); // secretAccessId demo
        setClientDetails(
          JSON.parse(
            handleDataDecryption(encryptedClientDetails, secretKey.toString()),
          ),
        );
        setRegulationCode(handleDataDecryption(encryptedRegulationCode, secretKey.toString()));
        const decryptedData = handleDataDecryption(encryptedProductCode, secretKey.toString());
        if (typeof decryptedData === 'string') {
          setProductCode(decryptedData);
        } else {
          setProductCode(
            JSON.parse(decryptedData),
          );
        }
      } catch (error) {
        // console.log('Error :', error);
        toast.error(error);
      }
    }
  };

  const handleDecryptResponse = (encryptedData, encryptionKey, iv) => {
    try {
      const encryptedDataBytes = CryptoJS.enc.Base64.parse(encryptedData);
      const ivBytes = CryptoJS.enc.Base64.parse(iv);
      const decryptedBytes = CryptoJS.AES.decrypt(
        { ciphertext: encryptedDataBytes },
        CryptoJS.enc.Utf8.parse(encryptionKey),
        { iv: ivBytes, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 },
      );
      const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
      const parsedData = JSON.parse(decryptedData);
      return parsedData;
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    handleGetDecryptedData();
  }, []);

  useEffect(() => {
    if (clientDetails !== null && Object.keys(clientDetails).length > 0) {
      handleEncryptValues(clientDetails);
    }
  }, [clientDetails]);

  return (
    <AppContext.Provider
      value={{
        state,
        dispatch,
        handleDataDecryption,
        handleDataEncryption,
        secretAccessKey,
        secretAccessId,
        inputBucketName,
        // inputBucketNameDemo, // input bucket name demo
        // secretAccessIdDemo, // secretAccess Id demo
        handleGetDecryptedData,
        clientDetails,
        setClientDetails,
        productCode,
        setProductCode,
        regulationCode,
        setRegulationCode,
        refColumnData,
        refRowData,
        setRefRowData,
        setRefColumnData,
        encryptedClientValuesObj,
        setEncryptedClientValuesObj,
        handleEncryptValue,
        handleEncryptValues,
        handleDecryptedValue,
        handleEncryptOtherParamsOfAPI,
        handleDecryptResponse,
      }}>
      {children}
    </AppContext.Provider>
  );
}
